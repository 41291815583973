import React from 'react';
import Address from '../../components/Address';
import Interval from '../../components/Interval';
import { differenceInDays } from 'date-fns';
import Date from '../../components/Date';
import { formatEuro, formatHours, formatDays } from '../Localization';
import { centToEuro } from '../Currency';
import { fromISO8601 as intervalFromISO8601 } from '../Interval';
import Zuschlagssatz from '../../components/Auftragsdetails/Zuschlagssatz';
import ZendeskSell from '../ZendeskSell';

const GueltigAb = props => (
  <span>
    ab <Date date={new window.Date(props.date)} />
  </span>
);

const mapAddress = address =>
  address && address.plz && address.stadt ? <Address {...address} /> : null;

const mapInterval = interval => {
  const { start, end } = intervalFromISO8601(interval);
  const days = start && end && differenceInDays(end, start) + 1;

  return (
    <React.Fragment>
      <Interval start={start} end={end} />
      {days ? (
        <React.Fragment>
          <br />
          <span>{formatDays(days, { decimals: false })}</span>
        </React.Fragment>
      ) : (
          ''
        )}
    </React.Fragment>
  );
};

const Gueltigkeiten = props => (
  <ul className="list-unstyled d-table w-100 mb-n5">
    {props.gueltigkeiten.map((g, index) => (
      <li className="d-flex justify-content-between mb-5" key={index}>
        <span>{g.wert}</span>{' '}
        <span className="text-right text-nowrap">
          <GueltigAb date={g.gueltigAb} className="d-table-cell" />
        </span>
      </li>
    ))}
  </ul>
);

const mapVerrechnungssaetze = verrechnungssaetze => {
  if (!verrechnungssaetze) return null;

  const gueltigkeiten = verrechnungssaetze.map(v => ({
    wert: formatEuro(centToEuro(v.hoeheInCent)),
    gueltigAb: v.gueltigAb
  }));

  return gueltigkeiten.length > 0 ? (
    <Gueltigkeiten gueltigkeiten={gueltigkeiten} />
  ) : null;
};

const mapSchwellwerte = schwellwerte => {
  if (!schwellwerte) return null;

  const gueltigkeiten = schwellwerte.map(s => ({
    wert: formatHours(s.inStunden, { decimals: false }),
    gueltigAb: s.gueltigAb
  }));

  return gueltigkeiten.length > 0 ? (
    <Gueltigkeiten gueltigkeiten={gueltigkeiten} />
  ) : null;
};

const mapZahlungsziele = zahlungsziele => {
  if (!zahlungsziele) return null;

  const gueltigkeiten = zahlungsziele.map(z => ({
    wert: formatDays(z.inTagen, { decimals: false }),
    gueltigAb: z.gueltigAb
  }));

  return gueltigkeiten.length > 0 ? (
    <Gueltigkeiten gueltigkeiten={gueltigkeiten} />
  ) : null;
};

const mapRechnungsturnusse = rechnungsturnusse => {
  if (!rechnungsturnusse) return null;

  const gueltigkeiten = rechnungsturnusse.map(r => ({
    wert: r.turnus,
    gueltigAb: r.gueltigAb
  }));

  return gueltigkeiten.length > 0 ? (
    <Gueltigkeiten gueltigkeiten={gueltigkeiten} />
  ) : null;
};

const mapZuschlagssaetze = zuschlagssaetze => {
  if (!zuschlagssaetze) return null;

  const gueltigkeiten = zuschlagssaetze.map(z => ({
    wert: <Zuschlagssatz zuschlaege={z.zuschlaege} />,
    gueltigAb: z.gueltigAb
  }));

  return gueltigkeiten.length > 0 ? (
    <Gueltigkeiten gueltigkeiten={gueltigkeiten} />
  ) : null;
};

const mapZendeskSellId = zendeskSellId => {
  if (!zendeskSellId) return null;

  return (
    <a
      href={ZendeskSell.contactEndpoint(zendeskSellId)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {zendeskSellId}
    </a>
  );
};

const map = (data) => ({
  kundenname: data.kundenname || (data.kunde && data.kunde.name),
  zendeskSellId: data.kunde && mapZendeskSellId(data.kunde.zendeskSellId),
  einsatzort: data.einsatzort && mapAddress(data.einsatzort),
  vertragsadresse: data.vertragsadresse && mapAddress(data.vertragsadresse),
  vertragsanschrift:
    data.vertragsanschrift && mapAddress(data.vertragsanschrift),
  anzahlDerMitarbeiter: data.anzahlDerMitarbeiter && data.anzahlDerMitarbeiter,
  einsatzzeitraum: data.einsatzzeitraum && mapInterval(data.einsatzzeitraum),
  vertragszeitraum: data.vertragszeitraum && mapInterval(data.vertragszeitraum),
  taetigkeit: data.taetigkeit,
  verrechnungssaetze:
    data.verrechnungssaetze && mapVerrechnungssaetze(data.verrechnungssaetze),
  schwellwerteFuerMehrarbeitszuschlag:
    data.schwellwerteFuerMehrarbeitszuschlag &&
    mapSchwellwerte(data.schwellwerteFuerMehrarbeitszuschlag),
  zuschlagssaetze:
    data.zuschlagssaetze && mapZuschlagssaetze(data.zuschlagssaetze),
  zahlungsziele: data.zahlungsziele && mapZahlungsziele(data.zahlungsziele),
  rechnungsturnusse:
    data.rechnungsturnusse && mapRechnungsturnusse(data.rechnungsturnusse),
  //   aenderungen: map
});

export default map;
